<template>
  <div class="Table" :key="renderKey">
    <div class="table__header">
      <div>
        <h6><b>Total rows with errors:</b> {{ totalErrors }}</h6>
      </div>
    <div class="table__end-align">
     <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-small
      class="d-inline-block"
      >
        <b-button
          :disabled="isLoading"
          class="table__end-align--button"
          @click="sendScale()"
          ><b-icon icon="reply-fill" aria-hidden="true"></b-icon>
          Send Quote</b-button
          >
        </b-overlay>
      </div>
    </div>
    <b-table
    id="scales-table"
    :items="items"
    :fields="fields"
    :per-page="perPage"
    :current-page="currentPage"
    show-empty
    >
      <template #empty="scope">
        <h6>{{ scope.emptyText }}</h6>
      </template>
      <template #cell()="row">
        <b-form-input
        v-if="row.field.key !== 'index'"
        type="text"
        :value="row.value"
        v-model="row.value"
        :class="row.field.key + row.index"
        :style="`border: 1px solid #9b9b9b`"
        @change="verifyFields(row)">
    </b-form-input>
    <div v-else>{{ row.item.index + 2 }} </div>
      </template>
    </b-table>
    <b-pagination
      class="table__pagination"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="scales-table"
      size="sm"
      align="center"
    ></b-pagination>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import * as XLSX from 'xlsx';

export default {
  name: 'Table',
  props: {
    file: {
      type: File,
      default: null,
    },
  },
  data() {
    return {
      fields: ['index', 'zip_pickup', 'zip_delivery', 'hu', 'weight', 'length', 'width', 'height'],
      items: [],
      isThereError: false,
      perPage: 20,
      currentPage: 1,
      rows: 0,
      isLoading: false,
      totalErrors: 0,
      validations: {
        canadaZipCode: /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/,
        intNumber: /^[1-9]\d*$/,
        number: /^-?\d+(\.\d+)?$/,
      },
      fieldColor: '#F44336',
      renderKey: 0,
    };
  },
  watch: {
    file() {
      this.$swal({
        icon: 'success',
        title: 'The file has been successfully uploaded',
        showConfirmButton: false,
        timer: 2000,
      });
      this.readingFile();
    },
  },
  methods: {
    readingFile() {
      const reader = new FileReader();
      reader.readAsArrayBuffer(this.file);
      // eslint-disable-next-line func-names
      reader.onload = function (event) {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        this.scalesJson = XLSX.utils.sheet_to_json(worksheet);
        this.validateFile();
      }.bind(this);
      this.renderKey += 1;
    },
    async validateFile() {
      const infoScaleErrors = [];
      this.items = [];
      this.scalesJson.forEach((element, index) => {
        const errorCordinates = {};
        this.isThereError = false;
        this.scalesJson[index].index = index;
        if (!this.validations.intNumber.test(element.hu)) {
          errorCordinates.hu = false;
          this.isThereError = true;
        }
        if (!this.validations.number.test(element.weight)) {
          errorCordinates.weight = false;
          this.isThereError = true;
        }
        if (!this.validations.number.test(element.height)) {
          errorCordinates.height = false;
          this.isThereError = true;
        }
        if (!this.validations.number.test(element.length)) {
          errorCordinates.length = false;
          this.isThereError = true;
        }
        if (!this.validations.number.test(element.width)) {
          errorCordinates.width = false;
          this.isThereError = true;
        }
        if (element.zip_delivery && element.zip_pickup) {
          const deliverySize = element.zip_delivery.toString();
          const pickupSize = element.zip_pickup.toString();
          if (!(this.validations.number.test(element.zip_delivery) && deliverySize.length >= 5)
          && !this.validations.canadaZipCode.test(element.zip_delivery)) {
            errorCordinates.zip_delivery = false;
            this.isThereError = true;
          }
          if (!(this.validations.number.test(element.zip_pickup) && pickupSize.length >= 5)
          && !this.validations.canadaZipCode.test(element.zip_pickup)) {
            errorCordinates.zip_pickup = false;
            this.isThereError = true;
          }
        } else if (element?.zip_delivery && !element?.zip_pickup) {
          errorCordinates.zip_pickup = false;
          this.isThereError = true;
        } else if (element?.zip_pickup && !element?.zip_delivery) {
          errorCordinates.zip_delivery = false;
          this.isThereError = true;
        }
        if (this.isThereError) {
          this.items.push(element);
        }
        if (Object.keys(errorCordinates).length >= 1) {
          infoScaleErrors.push(errorCordinates);
        }
      });
      setTimeout(() => {
        this.underLineErrors(infoScaleErrors);
      }, 150);
      this.totalErrors = this.items.length;
    },

    async underLineErrors(infoScaleErrors) {
      infoScaleErrors.forEach((rowInfo, index) => {
        const rowArray = Object.keys(rowInfo);
        rowArray.forEach((key) => {
          const input = document.getElementsByClassName(key + index);
          input[0].attributes[4].value = 'border: 1px solid #F44336';
        });
      });
    },
    verifyFields(row) {
      const input = document.getElementsByClassName(row.field.key + row.index);
      if (row.field.key.includes('zip')) {
        if (!this.validations.canadaZipCode.test(row.value)) {
          if (this.validations.number.test(row.value) && row.value.length >= 5) {
            input[0].attributes[4].value = 'border: 1px solid #00B900';
            this.scalesJson[row.item.index][row.field.key] = row.value;
          } else {
            input[0].attributes[4].value = 'border: 1px solid #F44336';
          }
        } else {
          input[0].attributes[4].value = 'border: 1px solid #00B900';
          this.scalesJson[row.item.index][row.field.key] = row.value;
        }
      } else if (row.field.key === 'hu') {
        if (this.validations.intNumber.test(row.value)) {
          input[0].attributes[4].value = 'border: 1px solid #00B900';
          this.scalesJson[row.item.index][row.field.key] = Number(row.value);
        } else {
          input[0].attributes[4].value = 'border: 1px solid #F44336';
        }
      } else if (this.validations.number.test(row.value)) {
        input[0].attributes[4].value = 'border: 1px solid #00B900';
        this.scalesJson[row.item.index][row.field.key] = Number(row.value);
      } else {
        input[0].attributes[4].value = 'border: 1px solid #F44336';
      }
    },
    async sendScale() {
      await this.validateFile();
      if (this.totalErrors === 0) {
        this.isLoading = true;
        const hauls = this.haulsConstructor();
        this.$store.commit('scale/setHauls', hauls);
        const response = await this.$store.dispatch('scale/sendQuote');
        if (response.status === 202) {
          this.$swal({
            icon: 'success',
            title: 'The scale has been sent',
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            this.$router.push({ name: 'Scales', params: { resetInfo: true } });
          });
        }
        this.isLoading = false;
      } else {
        this.$swal({
          title: 'Error',
          text: 'Please resolve all fields problems',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonColor: '#e53935',
        });
      }
    },

    haulsConstructor() {
      const hauls = [];
      this.scalesJson.forEach((element) => {
        const haul = {};
        haul.line_items = [];
        const lineItems = {
          weight: element.weight,
          length: element.length,
          width: element.width,
          height: element.height,
          hu_count: element.hu,
        };
        haul.order = hauls.length + 1;
        if (!element.zip_pickup && !element.zip_delivery) {
          hauls[hauls.length - 1].line_items.push(lineItems);
        } else {
          haul.zip_from = element.zip_pickup.toString().toUpperCase();
          haul.zip_to = element.zip_delivery.toString().toUpperCase();
          haul.line_items.push(lineItems);
          hauls.push(haul);
        }
      });
      return hauls;
    },
  },
};
</script>

<style scoped lang="scss">
.table{
    &__pagination{
      position: relative;
      z-index: 0;
      padding-bottom: 6rem;
      margin-bottom: 50px;
    }
    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center
    }
    &__end-align {
        display: flex;
        justify-content: end;
        margin-top: 2rem;
        &--button {
          @include primary-button;
          margin:30px
        }
    }
}
</style>
